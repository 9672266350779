import {createSlice} from '@reduxjs/toolkit'
import {CookieService} from "../services/CookieService";
export const accountSlice = createSlice({
    name: 'account',
    initialState: {
        accountIdent: undefined,
        accountStatusOk: false,
        languagePreference: undefined
    },
    reducers: {
        getLanguage: (state) => {
            const langPref = localStorage.getItem('language')
            return {
                ...state,
                languagePreference: langPref
            }
        },
        setLanguage: (state, action) => {
            state.languagePreference = localStorage.setItem('language', action.payload)
        },
        getAccount: (state) => {
            return (state)
        },
        setAccount: (state, action) => {
            return {
                ...state,
                accountIdent: action.payload.ident,
                accountStatusOk: action.payload.status
            };
        }
    }
})

// Action creators are generated for each case reducer function
export const {
    getLanguage,
    setLanguage,
    getAccount,
    setAccount
} = accountSlice.actions

export const fetchAccountData = () => async (dispatch) => {
    try {
        // Simulate fetching data from an API
        let cookieIdent = await CookieService()
        const response = await fetch(process.env.REACT_APP_API_URL + `/api/account/touch/${cookieIdent}`);
        const data = await response.json();

        dispatch(setAccount(data));
    } catch (error) {
        console.error('Error fetching account data:', error);
    }
};

export default accountSlice.reducer
