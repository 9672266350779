import {createSlice} from '@reduxjs/toolkit'
import {CookieService} from "../services/CookieService";

export const resultsSlice = createSlice({
    name: 'results',
    initialState: {
    },
    reducers: {
        getResults: (state) => {
            return(state)
        },
        updateResults: (state,action) => {
            return {
                ...state,
                resultsData: action.payload
            };
        }
    }
})
export const {
    getResults,
    updateResults
} = resultsSlice.actions

export const fetchResults = () => async (dispatch) => {
    try {
        let cookieIdent = CookieService()
        const response = await fetch(process.env.REACT_APP_API_URL + `/api/results/${cookieIdent}`);
        const data = await response.json();

        dispatch(updateResults(data));
    } catch (error) {
        console.error('Error fetching quiz results:', error);
    }
};

export default resultsSlice.reducer
