export const Select = ({options, selectedOption, defaultValue, handleOptionChange, style}) => {
    return (
        <select className={style} value={selectedOption} defaultValue={defaultValue} onChange={handleOptionChange}>
            {options.map(option => (
                <option key={option.value} value={option.value}>
                    {option.text}
                </option>
            ))}
        </select>
    )

}