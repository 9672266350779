export const FreeText = () => {
    return (
        <div>
            <p style={{fontSize: '1.25rem', marginBottom: '1rem'}}>Here you can write whatever you want within
                reasonable limitations:</p>
            <p style={{marginBottom: '1rem'}}>The text will be submitted for analysis, and you will receive a score and
                assessment of your submission.</p>
            <form action="/submit_text_block" method="post">
                <label style={{fontSize: '1.25rem', marginBottom: '0.5rem'}}>Enter your text (up to 1000
                    characters):</label>
                <textarea name="text_block" rows="5" maxLength="1000"
                          style={{width: '100%', padding: '0.5rem'}}></textarea>
                <button type="submit" style={{
                    backgroundColor: '#f8f9fa',
                    color: '#000',
                    padding: '0.5rem 1rem',
                    border: 'none',
                    cursor: 'pointer'
                }}>Submit
                </button>
            </form>
        </div>

    )
}