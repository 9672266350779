import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {fetchResults} from "../slices/ResultsSlice";
import './Results.css'

export const Results = () => {
    const availableResults = useSelector((state) => state.results.resultsData);
    const dispatch = useDispatch();

    useEffect(() => {
        // Dispatch getLanguage action only once when the component mounts
        dispatch(fetchResults())
    }, [dispatch]);  // Empty dependency array ensures this effect runs only once

    const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false, // Set to false to display in 24-hour format
    };

    function renderQuestion(questions, question_id) {
        return (
            questions
                .filter(q => q.id === question_id)
                .map(q => (
                    <div key={`root_question_a_${q.id}_${q.id}_key`}>
                        <div className='question' key={`question_a_${q.id}_${q.id}_key`}>Question:</div>
                        <div className='question-content' key={`question_b_${q.id}_${q.id}_key`}>{q.text}</div>
                    </div>
                ))
        )
    }

    function renderAnswer(answers, answer_id) {
        return (
            answers
                .filter(q => q.id === answer_id)
                .map((a) =>
                    <div key={`root_answer_a_${a.id}_${a.id}_key`}>
                        <div className='answer' key={`answer_a_${a.id}_${a.id}_key`}>Your answer:</div>
                        <div className='answer-content' key={`answer_b_${a.id}_${a.id}_key`}>{a.text}</div>
                    </div>
                )
        )
    }

    function renderQSQ(results, qsid) {
        return (
            results.qsq
                .filter(qq => qq.quiz_session_id === qsid)
                .map((qsq) => {
                    let scoreColor = qsq.total_score > 0 ? 'green' : 'red'
                    return (
                        <div className='result' key={`qsq_key_${qsq.quiz_session_id}_${qsq.id}`}>
                            <div>
                                {renderQuestion(results.questions, qsq.question_id)}
                                {renderAnswer(results.answers, qsq.answer_id)}
                            </div>
                            <div>
                                <div className='error-message'
                                     key={`qsq_status_key_${qsq.quiz_session_id}_${qsq.id}`}>
                                    {qsq.correct ? 'Correct answer' : 'Incorrect answer'}
                                </div>
                                <div className='points' key={`qsq_points_key_${qsq.quiz_session_id}_${qsq.id}`}>
                                    <span
                                        style={{color: `${scoreColor}`}}>points for this answer: {qsq.total_score}</span>
                                </div>
                            </div>
                        </div>
                    )
                })
        )
    }

    function renderTotalScore(results, qsid) {
        let totalScore = 0
        results.qsq
            .filter(q => q.quiz_session_id === qsid && q.total_score > 0)
            .map((q) =>
                totalScore = totalScore + q.total_score
            )
        return totalScore
    }

    function resultsInPercentage(results, qsid) {
        let totalAmount = 0
        let totalScore = 0
        results.qsq
            .filter(q => q.quiz_session_id === qsid)
            .map((q) => {
                totalAmount = totalAmount + 1
                if (q.total_score > 0) {
                    totalScore = totalScore + q.total_score
                }
            })
        return Math.round(totalScore / totalAmount * 100);
    }

    function renderResults(results) {
        if (results !== undefined) {
            if (results.sessions !== undefined) {
                return (
                    results.sessions.map((k) => {
                        const formattedDate = new Date(k.updated_at).toLocaleString(undefined, options);
                        return (
                            <div className='session-container' key={`sesh_key_${k.id}`}>
                                <h2 key={`sesh_id_key_${k.id}`}>Test {formattedDate}</h2>
                                <h4 key={`sesh_status_key_${k.id}`}>{k.completed ? 'Complete' : 'Incomplete'}</h4>
                                <h4>Total score: {renderTotalScore(results, k.id)}</h4>
                                <h5>
                                    Correct: {resultsInPercentage(results, k.id)}%
                                </h5>
                                <div className='qsq-container' key={`sesh_sub_key_${k.id}`}>
                                    {k.completed && renderQSQ(results, k.id)}
                                </div>
                            </div>
                        )
                    })
                )
            }
        }
    }

    return (
        <div className="main-results-container">
            {availableResults && renderResults(availableResults)}
        </div>
    )
}
