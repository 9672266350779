import {createSlice} from '@reduxjs/toolkit'
export const generalSlice = createSlice({
    name: 'general',
    initialState: {
        siteUserCount: 0,
        siteQuestionCount: 0
    },
    reducers: {
        setGeneralCounters: (state, action) => {
            return {
                ...state,
                counterData: action.payload
            };
        },
        getGeneralCounters: (state) => {
            return (
                state
            )
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    setGeneralCounters,
    getGeneralCounters
} = generalSlice.actions

export const fetchGeneralCounters = () => async (dispatch) => {
    try {
        // Simulate fetching data from an API
        const response = await fetch(process.env.REACT_APP_API_URL + `/api/general_counters`);
        const data = await response.json();

        // Dispatch the action with the updated counters
        dispatch(setGeneralCounters(data)); // You can pass the fetched data here
    } catch (error) {
        console.error('Error fetching general counters:', error);
    }
};

export default generalSlice.reducer
