import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';
import './App.css';
import './components/timeline.css'
import { Home } from './components/Home';
import { About } from './components/About';
import { Tests } from './components/Tests';
import { Results } from './components/Results';
import { FreeText } from './components/FreeText';
import Cookies from 'universal-cookie';
import { useDispatch, useSelector } from "react-redux";
import { fetchGeneralCounters } from "./slices/generalSice";

function App() {
    const siteCounters = useSelector((state) => state.general.counterData);
    const dispatch = useDispatch();

    useEffect(() => {
        document.title = "Singulingu"
    }, []);

    useEffect(() => {
        const lsLang = localStorage.getItem('language');
        if (lsLang === null) {
            localStorage.setItem('language', 'en');
        }
        function checkCookie() {
            const cookies = new Cookies();
            let anon_cookie_check = cookies.get('galingu_anon_cookie');
            if (anon_cookie_check === undefined) {
                cookies.set('galingu_anon_cookie', `${generateRandomSHA64()}`, { path: '/' });
            }
        }

        if (window.location.protocol !== 'https:' && process.env.REACT_APP_NODE_ENV === 'production') {
            window.location.href = 'https://' + window.location.hostname + window.location.pathname + window.location.search;
        }

        dispatch(fetchGeneralCounters())
        checkCookie();
    }, [dispatch]); // Empty dependency array ensures the effect runs only once


    function generateRandomSHA64() {
        const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let randomSHA = '';
        for (let i = 0; i < 64; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            randomSHA += characters.charAt(randomIndex);
        }
        return randomSHA;
    }

    function renderCounters() {
        return (
            <div style={{ fontSize: 12, marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
                <div style={{ marginRight: '5px' }}>users: {siteCounters.user_count}</div>
                <div style={{ marginRight: '5px' }}>questions: {siteCounters.question_count}</div>
                <div style={{ marginRight: '5px' }}>occupations: {siteCounters.occupation_count}</div>
            </div>
        )
    }

    return (
        <Router>
            <div className="App">
                <header>
                    <title>Singulingu - Language Training | Occupation-Specific Texts</title>
                    <meta name="description" content="Enhance language skills and access occupation-specific or any texts in Norwegian and English." />
                    <meta name="keywords" content="oslt, occupation-specific language training, Singulingu, language learning platform, Norwegian, English, spelling errors, occupation-specific texts, AI-generated texts, language training, flawed texts, flawless texts, no login, no account, no personal data, single cookie, identifier, no tracking, cultural viewpoints, old sayings, language learning process, cost of language courses, job requirements, occupation-related phrases, enhancing understanding, written language, language effectiveness, language skills, language enthusiast, improving written abilities, interactive test, randomized text, flaw identification, text analysis, understanding texts, dynamically generated questions, free" />

                    <meta name="language" content="en, no" />
                    {/*<meta http-equiv="content-language" content="en, no" />*/}

                    <meta name="robots" content="index, follow" />
                    <meta name="author" content='Lars "Juksefantomet" Grav' />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />

                    <meta property="og:title" content="Language Task Training | Occupation-Specific Texts | OSLT" />
                    <meta property="og:description" content="Boost language skills and access job-specific texts in Norwegian and English." />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://singulingu.com" />
                </header>
                <header>
                    <div className='nav-menu'>
                        <ul>
                            <li><NavLink to="/">Home</NavLink></li>
                            <li><NavLink to="/about">About</NavLink></li>
                            <li><NavLink to="/tests">Tests</NavLink></li>
                            <li><NavLink to="/results">Results</NavLink></li>
                            <li><span title={'Coming soon'} style={{ color: "darkgray" }}>Free text</span></li>
                            {/*<li><NavLink to="/free-text">Free text</NavLink></li>*/}

                        </ul>
                        {
                            siteCounters &&
                            renderCounters()
                        }
                    </div>
                </header>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/tests" element={<Tests />} />
                    <Route path="/results" element={<Results />} />
                    <Route path="/free-text" element={<FreeText />} />
                </Routes>
                <footer>
                    <p>&copy; {new Date().getFullYear()} Grav Alvorlig</p>
                    <p>Organisation number: 991888993</p>
                    <p>Contact</p>
                    <p>Contribute</p>
                </footer>
            </div>
        </Router>
    );
}

export default App;
