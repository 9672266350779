import {useEffect, useState} from 'react'
import {Select} from '../assets/Select'
import './Tests.css'
import {getQuiz, startQuiz, submitQuizAnswer} from "../slices/quizSlice";
import {fetchAccountData, getLanguage} from "../slices/accountSlice";
import {useDispatch, useSelector} from "react-redux";
import {Modal} from "./Modal";
import {fetchCategories, fetchOccupations} from "../slices/dataSlice";
import { useNavigate } from "react-router-dom";

export const Tests = () => {
    const [lang, setLang] = useState(checkLocalStorage('language') || 'en')
    const [storedLanguage, setStoredLanguage] = useState(checkLocalStorage('language') || 'en')
    const [getOccupation, setOccupation] = useState(1)
    const [isModalVisible, setModalVisible] = useState(false);
    const [activeSelectedCSSAnswer, setactiveSelectedCSSAnswer] = useState(null)
    const [activeAnswer, setActiveAnswer] = useState(null)
    const [currentQuizSessionQuestion, setCurrentQuizSessionQuestion] = useState(null)
    const [currentQuizQuestion, setCurrentQuizQuestion] = useState(null)
    const [currentQuizSession, setCurrentQuizSession] = useState(null)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const quizData = useSelector(state => state.quiz.quizData)
    const siteData = useSelector(state => state.siteData)

    let availableOccupations = setOccupationsArray(siteData.occupations)

    useEffect(() => {
        // Dispatch getLanguage action only once when the component mounts
        dispatch(fetchAccountData())
        dispatch(getLanguage())
        dispatch(fetchOccupations())
        dispatch(fetchCategories())
        checkLocalStorage()
    }, [dispatch]);  // Empty dependency array ensures this effect runs only once

    const closeModal = () => {
        document.body.style.overflow = '';
        setModalVisible(false);

        if (activeSelectedCSSAnswer !== null) {
            let prev = document.getElementById(activeSelectedCSSAnswer)
            prev.classList.remove('quiz-list-element-selected')
            setactiveSelectedCSSAnswer(null)
        }
    };

    const handleOutsideClick = (event) => {
        if (event.target.id === 'myModal') {
            closeModal();
        }
    };

    function setOccupationsArray(ocs) {
        let tempArray = []
        if (ocs !== undefined) {
            ocs.forEach((o) => {
                    if (`${o.language}` === `${storedLanguage}`) {
                        tempArray.push({value: o.id, text: o.name})
                    }
                }
            )
        }
        return (tempArray)
    }

    async function startTest() {
        await dispatch(startQuiz(getOccupation));
        dispatch(getQuiz());

        document.body.style.overflow = 'hidden';
        setModalVisible(true);
    }

    function checkLocalStorage(arg) {
        let returnValue = undefined;
        let lsLingu = 'en'
        if (arg === 'language') {
            lsLingu = localStorage.getItem(arg)
            if (lsLingu === 'undefined') {
                localStorage.setItem(arg, 'en')
            } else {
                lsLingu = localStorage.getItem(arg)
            }
            returnValue = lsLingu
        }
        return returnValue
    }

    function updateLanguage() {
        setStoredLanguage(lang)
        localStorage.setItem('language', lang)
    }

    function handleLanguageChange(e) {
        setLang(e.target.value)
    }

    function handleOccupationChange(e) {
        setOccupation(e.target.value)
    }

    // let availableOccupations = [
    //     {value: '1', text: 'any'},
    //     {value: '2', text: 'writer'},
    //     {value: '3', text: 'c'},
    //     {value: '4', text: 'trompet master'},
    //     {value: '5', text: 'master baiter'}
    // ]

    let availableLanguages = [
        {value: 'en', text: 'English'},
        {value: 'no', text: 'Norwegian'}
    ]

    function renderAnswerData(ar, q) {
        let current_answer = ''
        quizData.answers.forEach((a) => {
                if (a.id === ar.answer_id && ar.question_id === q.id) {
                    current_answer = a.text
                }
            }
        )
        return (current_answer)
    }

    function selectListElement(ele, answerId, currentQSQ, currentQuizQ, currentQS) {
        let prevElement = activeSelectedCSSAnswer
        let element = ele;
        if (prevElement !== null) {
            let prev = document.getElementById(activeSelectedCSSAnswer)
            prev.classList.toggle('quiz-list-element-selected')
        }
        element.classList.toggle('quiz-list-element-selected')
        setactiveSelectedCSSAnswer(element.id)
        setActiveAnswer(answerId)
        setCurrentQuizSessionQuestion(currentQSQ)
        setCurrentQuizQuestion(currentQuizQ)
        setCurrentQuizSession(currentQS)
    }

    async function submitAnswer() {
        // post '/api/submit_answer/:ident/:qsq_id/:qs_id/:question_id/:answer_id', to: 'api#submit_answer'
        // currentQuizSessionQuestion, currentQuizSession, currentQuizQuestion, activeAnswer
        await dispatch(submitQuizAnswer(currentQuizSession, activeAnswer, currentQuizSessionQuestion, currentQuizQuestion));
        setActiveAnswer(null)
        setCurrentQuizSessionQuestion(null)
        setCurrentQuizQuestion(null)
        setCurrentQuizSession(null)
        if (quizData.quiz_session_questions.length > 1) {
            if (activeSelectedCSSAnswer !== null) {
                let prev = document.getElementById(activeSelectedCSSAnswer)
                prev.classList.remove('quiz-list-element-selected')
                setactiveSelectedCSSAnswer(null)
            }
            startTest()
        } else {
            navigate("/results");
            closeModal()
        }
    }

    function renderQuizData(quizData) {
        let currentQuizQ = undefined
        let currentQSQ = undefined
        let currentQS = undefined
        quizData.quiz_session_questions.map((qsq) => {
            if (qsq.completed === false) {
                currentQuizQ = qsq.question_id
                currentQSQ = qsq.id
                currentQS = qsq.quiz_session_id
            }
        })

        return (
            quizData.questions.map((q) => {
                let currentIdent = undefined
                    if (`${q.id}` === `${currentQuizQ}`) {
                        return (
                            <div className='main-modal-content' key={`${currentQSQ}_${q.id}_${currentQuizQ}`}>
                                <div className='quiz-question-h1'>{q.text}</div>
                                {quizData.answer_references.map((ar) => {
                                        if (currentIdent === undefined && q.id === ar.question_id) {
                                            currentIdent = ar.set_ident
                                        }
                                        if (`${currentIdent}` === `${ar.set_ident}`) {
                                            let answer_text = renderAnswerData(ar, q);
                                            if (answer_text !== '') {
                                                return (
                                                    <li
                                                        id={`${currentQSQ}_${q.id}_${currentQuizQ}_${ar.id}`}
                                                        className='quiz-list-element'
                                                        key={`${ar.answer_id}_ans_${ar.points}`}
                                                        onClick={(e) => selectListElement(e.target, ar.answer_id, currentQSQ, currentQuizQ, currentQS)}>
                                                        {answer_text}
                                                    </li>
                                                )
                                            }
                                        }


                                    }
                                )}
                                <button
                                    className='send-button'
                                    onClick={((e) => submitAnswer())}
                                >Send
                                </button>
                            </div>
                        )
                    }
                }
            )
        )
    }

    return (
        <div className="container-fluid">
            <main role="main">
                <div style={{backgroundColor: '#f8f9fa', padding: '2rem', textAlign: 'center'}}>
                    <div className="timeline" style={{textAlign: 'left'}}>
                        <div className="container left">
                            <div className="content">
                                <h2>Set your language</h2>
                                <p>Current language: {storedLanguage === 'en' ? 'English' : 'Norwegian'}</p>
                                <Select options={availableLanguages} selectedOption={lang} value={storedLanguage}
                                        handleOptionChange={(e) => handleLanguageChange(e)}/>
                                <button onClick={() => updateLanguage()}>Update Language</button>
                            </div>
                        </div>
                        <div className="container right">
                            <div className="content">
                                <h2>Begin or continue your test here</h2>
                                {siteData.occupations &&
                                    <div>
                                        <Select style={'custom-select'}
                                                options={availableOccupations}
                                                selectedOption={getOccupation}
                                                handleOptionChange={(e) => handleOccupationChange(e)}
                                        />
                                        <button className="start-button" onClick={(e) => startTest()}>Start</button>
                                    </div>
                                }
                                <p>
                                    <b>Start any test to continue if you aborted prematurely</b>
                                </p>
                            </div>
                        </div>
                        <div className="container left">
                            <div className="content">
                                <h2>The purpose</h2>
                                <p>
                                    This interactive test is designed to help you
                                    sharpen your existing language skills. Whether you're a language
                                    enthusiast, a professional writer, or someone looking to improve their written
                                    abilities, this quiz is for you.
                                </p>
                            </div>
                        </div>
                        <div className="container right">
                            <div className="content">
                                <h2>Content</h2>
                                <p>
                                    Choose from a variety of occupations or select any to get a mixture of many.
                                    Once you've selected your occupation, the quiz will present you with 10 question sets with randomized text answer alternatives,
                                    related to your selected occupation or from all. a question either focuses on identifying a flaw or the flawless option.
                                </p>
                            </div>
                        </div>
                        <div className="container left">
                            <div className="content">
                                <h2>Recommendations</h2>
                                <p>
                                    During your testing, if you come across phrases or texts you have a hard time understanding. Spend your time well and research!
                                </p>
                                <p>
                                    This is just as much about understanding the texts you analyze, not just picking the right answer.
                                </p>
                            </div>
                        </div>

                        <div className="container right">
                            <div className="content">
                                <h2>Availability</h2>
                                <p>
                                    The available occupations are dependent on how many questions are generated within that occupation. If you are unable to locate
                                    an occupation suited for you, i advise you to check in later as the questions are dynamically generated over time and will eventually
                                    be available. This is to ensure there is enough questions to go with one occupation.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {isModalVisible &&
                    <div onClick={handleOutsideClick}>
                        <Modal header="x" text="Quiz Time" isVisible={isModalVisible} onClose={closeModal}>
                            {
                                renderQuizData(quizData)
                            }
                        </Modal>
                    </div>
                }

            </main>
        </div>
    )
}
