// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  margin-top: 20px;
  text-align: center;
}

.nav-menu {
  margin-bottom: 10px;
}

.nav-menu ul {
  width: 100%; /* Set width to 100% */
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0 auto; /* Set margin to auto */
}

.nav-menu li {
  margin: 0;
  padding: 0;
}

.nav-menu a {
  text-decoration: none;
  padding: 10px 15px;
  color: #333;
  transition: color 0.3s;
}

.nav-menu a:hover {
  color: #007bff;
}

footer {
  display: flex;
  justify-content: center;
  background-color: #f4f4f4;
  padding: 10px 0;
  margin-top: 20px;
}

footer p {
  margin: 0 10px;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW,EAAE,sBAAsB;EACnC,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,UAAU;EACV,cAAc,EAAE,uBAAuB;AACzC;;AAEA;EACE,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,WAAW;EACX,sBAAsB;AACxB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,yBAAyB;EACzB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".App {\n  margin-top: 20px;\n  text-align: center;\n}\n\n.nav-menu {\n  margin-bottom: 10px;\n}\n\n.nav-menu ul {\n  width: 100%; /* Set width to 100% */\n  list-style: none;\n  display: flex;\n  justify-content: center;\n  padding: 0;\n  margin: 0 auto; /* Set margin to auto */\n}\n\n.nav-menu li {\n  margin: 0;\n  padding: 0;\n}\n\n.nav-menu a {\n  text-decoration: none;\n  padding: 10px 15px;\n  color: #333;\n  transition: color 0.3s;\n}\n\n.nav-menu a:hover {\n  color: #007bff;\n}\n\nfooter {\n  display: flex;\n  justify-content: center;\n  background-color: #f4f4f4;\n  padding: 10px 0;\n  margin-top: 20px;\n}\n\nfooter p {\n  margin: 0 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
