export const Home = () => {
    return (
        <div className="container-fluid">
            <main role="main">
                <div style={{backgroundColor: '#f8f9fa', padding: '2rem', textAlign: 'center'}}>
                    <p style={{fontSize: '1.25rem', marginBottom: '1rem'}}>
                        Singular occupation or any, linguistics training
                    </p>
                    <p style={{fontSize: '1.25rem', marginBottom: '1rem'}}>Singulingu - is a language learning
                        platform designed for individuals who are currently learning Norwegian or English.
                    </p>
                    <hr style={{border: '1px solid #ddd', margin: '2rem 0'}}/>

                    <div className="timeline" style={{textAlign: 'left'}}>
                        <div className="container left">
                            <div className="content">
                                <h2>Hone your skillset</h2>
                                <p style={{marginBottom: '1rem'}}>Our platform focuses on providing training texts that help
                                    enhance your ability to identify spelling errors and similar in either English or Norwegian, but also narrowed to specific occupations. This is commonly referred to as OSLT, or Occupation-Specific Language Training.
                                </p>
                            </div>
                        </div>
                        <div className="container right">
                            <div className="content">
                                <h2>Occupation specific or any text</h2>
                                <p style={{marginBottom: '1rem'}}>We offer a variety of practice texts tailored to specific
                                    occupations, e.g healthcare, to provide language training texts within specialized areas
                                    of expertise.</p>
                            </div>
                        </div>
                        <div className="container left">
                            <div className="content">
                                <h2>Combined effort</h2>
                                <p style={{marginBottom: '1rem'}}>
                                    Feel free to explore our tests and hone your ability to spot flawed or flawless texts, either in any occupation or a specific one.
                                    This site utilizes AI to generate sample texts but human created algorithms to match texts with questions and relations.
                                </p>
                            </div>
                        </div>
                    </div>

                    <hr style={{border: '1px solid #ddd', margin: '2rem 0'}}/>
                    <p>
                        <span>In addition to all this, the site itself requires </span>
                        <span style={{textDecoration: 'underline', fontWeight: 'bold'}}>no login</span>,
                        <span style={{textDecoration: 'underline', fontWeight: 'bold'}}> no account</span> or personal data.
                    </p>
                    <p>
                        We utilize a single cookie with a single random generated "identifier" which has no meaning outside this sites domain, meaning there is absolutely no tracking.
                        If if you delete your browser data, you delete your user.
                    </p>
                    <p>
                        We have no interest in your personal data, thereby not collecting any.
                    </p>
                </div>
            </main>
        </div>

    )
}