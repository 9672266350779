import {configureStore} from "@reduxjs/toolkit";
import resultsReducer from './slices/ResultsSlice'
import accountReducer from "./slices/accountSlice";
import generalReducer from "./slices/generalSice";
import siteDataReducer from "./slices/dataSlice";
import quizReducer from "./slices/quizSlice";

export default configureStore({
    reducer: {
        general: generalReducer,
        account: accountReducer,
        results: resultsReducer,
        siteData: siteDataReducer,
        quiz: quizReducer
    }
})
