export const About = () => {

    return (
        <div className="container-fluid">
            <main role="main">
                <div style={{backgroundColor: '#f8f9fa', padding: '2rem', textAlign: 'center'}}>
                    <div className="timeline" style={{textAlign: 'left'}}>
                        <div className="container left">
                            <div className="content">
                                <h2>Oh, storytime?</h2>
                                <p style={{marginBottom: '1rem'}}>Singulingu was born out of the passion for languages by its owner,
                                    Lars Grav, who has a keen interest in language learning. After
                                    working for 7 years in the private healthcare sector in Norway, Lars had extensive interactions
                                    with immigrants learning Norwegian.</p>
                                <p style={{marginBottom: '1rem'}}>During these interactions, some things became quite clear.</p>
                            </div>
                        </div>
                        <div className="container right">
                            <div className="content">
                                <h2>Findings</h2>
                                <ol style={{paddingLeft: 0}}>
                                    <li style={{marginBottom: '1rem'}}>Cultural viewpoints and "old sayings" played a significant
                                        role in the language learning process.
                                    </li>
                                    <li style={{marginBottom: '1rem'}}>The cost of language courses was often a barrier, especially
                                        after long work hours.
                                    </li>
                                    <li style={{marginBottom: '1rem'}}>The general nature of tests didn't always align with specific
                                        job requirements, leaving students feeling they lacked certain information.
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <div className="container left">
                            <div className="content">
                                <h2>The plan</h2>
                                <p style={{marginBottom: '1rem'}}>To address these gaps, Singulingu was created to provide a platform
                                    for learners to explore various occupation-related phrases, and angles that are often
                                    overlooked in traditional tests.</p>
                            </div>
                        </div>
                        <div className="container right">
                            <div className="content">
                                <h2>Goals</h2>
                                <ol style={{paddingLeft: 0}}>
                                    <li style={{marginBottom: '1rem'}}>Adding specific terms and phrases used in various
                                        occupation-related topics.
                                    </li>
                                    <li style={{marginBottom: '1rem'}}>Enhancing understanding and self-awareness in written language of your practice choice.
                                    </li>
                                    <li style={{marginBottom: '1rem'}}>Providing insights and guidance for using the language
                                        effectively in different occupations.
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}