import React from 'react';
import './Modal.css';

export const Modal = ({header, text, isVisible, onClose, children}) => {
    return (
        <div id="myModal" className="modal" style={{display: isVisible ? 'flex' : 'none'}}>
            <div className="modal-content">
                <span className="close" onClick={onClose}>{header}</span>
                <p>{text}</p>
                <div>
                    {children}
                </div>
            </div>
        </div>
    );
};
