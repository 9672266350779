import {createSlice} from '@reduxjs/toolkit'
import {CookieService} from "../services/CookieService";

export const quizSlice = createSlice({
    name: 'quiz',
    initialState: {
    },
    reducers: {
        getQuiz: (state) => {
            return (state)
        },
        setQuiz: (state, action) => {
            return {
                ...state,
                quizData: action.payload
            };
        }
    }
})

// Action creators are generated for each case reducer function
export const {
    getQuiz,
    setQuiz
} = quizSlice.actions

export const startQuiz = (occupationId) => async (dispatch) => {
    try {
        let langPref = localStorage.getItem('language')
        let cookieIdent = CookieService()
        const response = await fetch(process.env.REACT_APP_API_URL + `/api/start_quiz/${cookieIdent}/${occupationId}/${langPref}`);
        const data = await response.json();

        dispatch(setQuiz(data));
    } catch (error) {
        console.error('Error fetching quiz data:', error);
    }
};

export const submitQuizAnswer = (quizSessionId, selectedAnswerId, quizSessionQuestionId, selectedQuestionId) => async (dispatch) => {
    try {
        let cookieIdent = CookieService()
        // post '/api/submit_answer/:ident/:qsq_id/:qs_id/:question_id/:answer_id', to: 'api#submit_answer'
        const response = await fetch(process.env.REACT_APP_API_URL + `/api/submit_answer/${cookieIdent}/${quizSessionQuestionId}/${quizSessionId}/${selectedQuestionId}/${selectedAnswerId}`,
            {method: 'POST'});
        await response.json();
    } catch (error) {
        console.error('Error submitting question details data:', error);
    }
};

export default quizSlice.reducer
