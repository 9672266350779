import {createSlice} from '@reduxjs/toolkit'
export const siteDataSlice = createSlice({
    name: 'siteData',
    initialState: {
    },
    reducers: {
        setOccupations: (state, action) => {
            return {
                ...state,
                occupations: action.payload.occupations
            };
        },
        getOccupations: (state) => state,
        setCategories: (state, action) => {
            return {
                ...state,
                categories: action.payload.categories
            };
        },
        getCategories: (state) => state,
    },
})

// Action creators are generated for each case reducer function
export const {
    getOccupations,
    getCategories,
    setOccupations,
    setCategories
} = siteDataSlice.actions

export const fetchOccupations = () => async (dispatch) => {
    try {
        // Simulate fetching data from an API
        const response = await fetch(process.env.REACT_APP_API_URL + `/api/fetch_occupations`);
        const data = await response.json();

        dispatch(setOccupations(data));
    } catch (error) {
        console.error('Error fetching occupations:', error);
    }
};

export const fetchCategories = () => async (dispatch) => {
    try {
        // Simulate fetching data from an API
        const response = await fetch(process.env.REACT_APP_API_URL + `/api/fetch_categories`);
        const data = await response.json();

        dispatch(setCategories(data));
    } catch (error) {
        console.error('Error fetching categories:', error);
    }
};

export default siteDataSlice.reducer
